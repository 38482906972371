import React, { Component } from "react";
import "./ListingCard.css";

class ListingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { name, contact_number, title, room_type, address, monthly_rent, monthly_utilities_estimate, highlights, male_flatmates_count, female_flatmates_count, created_at, start_date, end_date } = this.props;

    return (
      <div className="listing-card margin-tlr rounded-box">
        <h5 className="center">{title}</h5>
        <div className="display-flex-row mt-20">
          <div className="display-flex-col">
            <div><b>Address:</b> {address}</div>
            <div><b>Start Date:</b> {start_date}</div>
            <div><b>By:</b> {name}</div>
          </div>
          <div className="display-flex-col">
            <div><b>Monthly Rent:</b> ${monthly_rent}</div>
            <div><b>End Date:</b> {end_date}</div>
            <div><b>Contact Number:</b> {contact_number}</div>
          </div>
          <div className="display-flex-col">
            <div><b>Utilities Estimate:</b> ${monthly_utilities_estimate}</div>
            <div><b>Male Flatmates:</b> {male_flatmates_count}</div>
            <div><b>Date Posted:</b> {created_at}</div>
          </div>
          <div className="display-flex-col">
            <div><b>Room Type:</b> {room_type}</div>
            <div><b>Female Flatmates:</b> {female_flatmates_count}</div>
          </div>
        </div>
        {highlights &&
          <div className="mt-20"><b>Highlights:</b> {highlights}</div>
        }
      </div>
    );
  }
}

export default ListingCard;
