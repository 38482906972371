import React, { Component } from "react";
import axios from "axios";
import "./Home.css";
import ListingCard from "../ListingCard/ListingCard";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from "reactstrap";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listings: null,
      address_keyword: "",
      flatmates_gender: "",
      room_type: "",
      max_monthly_rent: "",
      start_date: "",
      end_date: ""
    };
  }

  fetchData = async () => {
    const { address_keyword, flatmates_gender, room_type, max_monthly_rent, start_date, end_date } = this.state;

    const formatted_start_date = start_date ? start_date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).replace(/\//g, '-') : "";

    const formatted_end_date = end_date ? end_date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).replace(/\//g, '-') : "";

    try {
      const response = await axios.get(`https://api.ssublease.com/getListings?addressKeyword=${address_keyword.trim()}&flatmatesGender=${flatmates_gender}&roomType=${room_type}&maxMonthlyRent=${max_monthly_rent}&startDate=${formatted_start_date}&endDate=${formatted_end_date}`);
      this.setState({ listings: response.data.Listings });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`;
    return formattedDate;
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleDropdownChange = (event) => {
    const { id, value } = event.target;
    this.setState({ [id]: value });
  };

  handleStartDateChange = (date) => {
    this.setState({ start_date: date });
  };

  handleEndDateChange = (date) => {
    this.setState({ end_date: date });
  };

  clearButtonClicked = () => {
    this.setState({ address_keyword: "", flatmates_gender: "", room_type: "", max_monthly_rent: "", start_date: "", end_date: "" }, () => { this.fetchData(); });
  };

  render() {
    const { listings, address_keyword, flatmates_gender, room_type, max_monthly_rent, start_date, end_date } = this.state;

    return (
      <div className="margin-40">
        <div className="display-flex-row">
          <div>
            Address Keywords: &nbsp;
            <input
              type="text"
              id="address_keyword"
              name="address_keyword"
              value={address_keyword}
              onChange={this.handleInputChange}
            />
          </div>

          <div>
            Max Monthly Rent: &nbsp;
            <input
              type="number"
              id="max_monthly_rent"
              name="max_monthly_rent"
              value={max_monthly_rent}
              onChange={this.handleInputChange}
            />
          </div>

          <div>
            Start Date: &nbsp;
            <DatePicker
              id="start_date"
              selected={start_date}
              onChange={this.handleStartDateChange}
              dateFormat="MM-dd-yyyy"
              maxDate={end_date}
            />
          </div>

          <div>
            End Date: &nbsp;
            <DatePicker
              id="end_date"
              selected={end_date}
              onChange={this.handleEndDateChange}
              dateFormat="MM-dd-yyyy"
              minDate={start_date}
            />
          </div>

          <div>
            Flatmates Preference: &nbsp;
            <select id="flatmates_gender" value={flatmates_gender} onChange={this.handleDropdownChange}>
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>

          <div>
            Room Type: &nbsp;
            <select id="room_type" value={room_type} onChange={this.handleDropdownChange}>
              <option value="">Select Room</option>
              <option value="Private">Private</option>
              <option value="Shared">Shared</option>
            </select>
          </div>
        </div>

        <div className="buttons mt-20">
          <Button className="button-margin" onClick={this.fetchData}>
            Filter Listings
          </Button>

          <Button className="button-margin ml-20" onClick={this.clearButtonClicked}>
            Clear
          </Button>
        </div>
        <br/>

        {listings ? listings.map(l => (
          <ListingCard
            key={l.Id}
            title={l.Title}
            name={l.Username}
            address={l.Address}
            contact_number={l.ContactNumber}
            monthly_rent={l.MonthlyRent}
            monthly_utilities_estimate={l.MonthlyUtilitiesEstimate}
            room_type={l.RoomType}
            start_date={l.StartDate}
            end_date={l.EndDate}
            male_flatmates_count={l.MaleFlatmatesCount}
            female_flatmates_count={l.FemaleFlatmatesCount}
            created_at={this.formatDate(l.CreatedAt)}
            highlights={l.Highlights}
          />
        )) : "No listings available!"}
      </div>
    );
  }
}

export default Home;
