import React, { Component } from "react";
import { Button } from "reactstrap";
import axios from "axios";
import "./PostListing.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class PostListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      name: '',
      address: '',
      contact_number: '',
      monthly_rent: '',
      monthly_utilities_estimate: '',
      room_type: '',
      start_date: '',
      end_date: '',
      male_flatmates_count: '',
      female_flatmates_count: '',
      highlights: '',

      title_error: '',
      name_error: '',
      address_error: '',
      contact_error: '',
      rent_error: '',
      utilities_error: '',
      room_type_error: '',
      start_date_error: '',
      end_date_error: '',
      male_flatmates_error: '',
      female_flatmates_error: '',
      save_error: null
    };
  }

  submitButtonClicked = async () => {
    let { title, name, address, contact_number, monthly_rent, monthly_utilities_estimate, room_type, start_date, end_date, male_flatmates_count, female_flatmates_count, highlights } = this.state;
    let error_on_submit = false;
    const numeric_pattern = /^\d+$/;

    if (!title) {
      this.setState({ title_error: 'Title cannot be empty' });
      error_on_submit = true;
    }

    if (!name) {
      this.setState({ name_error: 'Name cannot be empty' });
      error_on_submit = true;
    }

    if (!address) {
      this.setState({ address_error: 'Address cannot be empty' });
      error_on_submit = true;
    }

    if (Number(contact_number) <= 0) {
      this.setState({ contact_error: 'Invalid value for contact number' });
      error_on_submit = true;
    } else if(!numeric_pattern.test(contact_number)) {
      this.setState({ contact_error: 'Contact number should be numeric' });
      error_on_submit = true;
    }

    if (Number(monthly_rent) <= 0) {
      this.setState({ rent_error: 'Invalid value for monthly rent' });
      error_on_submit = true;
    } else if(!numeric_pattern.test(monthly_rent)) {
      this.setState({ rent_error: 'Monthly rent should be numeric without decimal' });
      error_on_submit = true;
    }

    if (Number(monthly_utilities_estimate) <= 0) {
      this.setState({ utilities_error: 'Invalid value for utilities estimate' });
      error_on_submit = true;
    } else if(!numeric_pattern.test(monthly_utilities_estimate)) {
      this.setState({ utilities_error: 'Utilities estimate should be numeric without decimal' });
      error_on_submit = true;
    }

    if (!start_date) {
      this.setState({ start_date_error: 'Start date cannot be empty' });
      error_on_submit = true;
    }

    if (!end_date) {
      this.setState({ end_date_error: 'End date cannot be empty' });
      error_on_submit = true;
    }

    if (!male_flatmates_count) {
      this.setState({ male_flatmates_error: 'Count cannot be empty' });
      error_on_submit = true;
    } else if(!numeric_pattern.test(male_flatmates_count)) {
      this.setState({ male_flatmates_error: 'Count should be numeric' });
      error_on_submit = true;
    }

    if (!female_flatmates_count) {
      this.setState({ female_flatmates_error: 'Count cannot be empty' });
      error_on_submit = true;
    } else if(!numeric_pattern.test(female_flatmates_count)) {
      this.setState({ female_flatmates_error: 'Count should be numeric' });
      error_on_submit = true;
    }

    if (!room_type) {
      this.setState({ room_type_error: 'Room type cannot be empty' });
      error_on_submit = true;
    }

    if (error_on_submit)
      return;

    const formatted_start_date = start_date ? start_date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).replace(/\//g, '-') : "";

    const formatted_end_date = end_date ? end_date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).replace(/\//g, '-') : "";

    const payload = {
      Username: name,
      ContactNumber: contact_number,
      Title: title,
      RoomType: room_type,
      StartDate: formatted_start_date,
      EndDate: formatted_end_date,
      Address: address,
      MonthlyRent: parseFloat(monthly_rent),
      MonthlyUtilitiesEstimate: parseFloat(monthly_utilities_estimate),
      MaleFlatmatesCount: parseInt(male_flatmates_count),
      FemaleFlatmatesCount: parseInt(female_flatmates_count),
      Highlights: highlights,
    }

    try {
      const response = await axios.post(`https://api.ssublease.com/addListing`, payload);

      if (response.status === 200) {
        // Redirect to view listings page
        window.location.href = '/';
      }
    } catch (error) {
      if (error?.response?.data) {
        this.setState({ save_error: error.response.data });
      } else {
        alert('Something went wrong');
      }
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;

    let error_name = '';
    if (name === 'title')
      error_name = 'title_error';
    else if (name === 'name')
      error_name = 'name_error';
    else if (name === 'address')
      error_name = 'address_error';
    else if (name === 'contact_number')
      error_name = 'contact_error';
    else if (name === 'monthly_rent')
      error_name = 'rent_error';
    else if (name === 'monthly_utilities_estimate')
      error_name = 'utilities_error';
    else if (name === 'male_flatmates_count')
      error_name = 'male_flatmates_error';
    else if (name === 'female_flatmates_count')
      error_name = 'female_flatmates_error';

    this.setState({ [name]: value, [error_name]: '' });
  };

  handleDropdownChange = (event) => {
    const { id, value } = event.target;
    this.setState({ [id]: value, room_type_error: '' });
  };

  handleStartDateChange = (date) => {
    this.setState({ start_date: date, start_date_error: '' });
  };

  handleEndDateChange = (date) => {
    this.setState({ end_date: date, end_date_error: '' });
  };

  clearButtonClicked = () => {
    this.setState({title: '', name: '', address: '', contact_number: '', monthly_rent: '', monthly_utilities_estimate: '', room_type: '', start_date: '', end_date: '', male_flatmates_count: '', female_flatmates_count: '', highlights: '', title_error: '', name_error: '', address_error: '', contact_error: '', rent_error: '', utilities_error: '', room_type_error: '', start_date_error: '', end_date_error: '', male_flatmates_error: '', female_flatmates_error: ''});
  };

  render() {
    const { title, name, address, contact_number, monthly_rent, monthly_utilities_estimate, room_type, start_date, end_date, male_flatmates_count, female_flatmates_count, highlights, title_error, name_error, address_error, contact_error, rent_error, utilities_error, room_type_error, start_date_error, end_date_error, male_flatmates_error, female_flatmates_error, save_error } = this.state;

    return (
      <div className="post-listing">

        <h2>Post Your Listing</h2>
        <div className="post-listing-form">

          <div className="form-group">
            <label htmlFor="title">Listing Title</label>
            <input type="text" className="form-control" name="title" value={title} onChange={this.handleInputChange} />
            {title_error && <small className="form-text text-danger">{title_error}</small>}
          </div>

          <div className="form-group">
            <label htmlFor="name">Your Name</label>
            <input type="text" className="form-control" name="name" value={name} onChange={this.handleInputChange} />
            {name_error && <small className="form-text text-danger">{name_error}</small>}
          </div>

          <div className="form-group">
            <label htmlFor="address">Address</label>
            <input type="text" className="form-control" name="address" value={address} onChange={this.handleInputChange} />
            <small className="form-text text-muted">Please enter in this format: Building Number, Street Number, Neighborhood, City, State, Pincode. Example: 246, 88 St, Bay Ridge, Brooklyn, New York, NY 11209</small><br/>
            {address_error && <small className="form-text text-danger">{address_error}</small>}
          </div>

          <div className="form-group">
            <label htmlFor="contact_number">Contact Number</label>
            <input type="number" className="form-control" name="contact_number" value={contact_number} onChange={this.handleInputChange} />
            {contact_error && <small className="form-text text-danger">{contact_error}</small>}
          </div>

          <div className="form-group">
            <label htmlFor="monthly_rent">Monthly Rent</label>
            <input type="number" className="form-control" name="monthly_rent" value={monthly_rent} onChange={this.handleInputChange} />
            {rent_error && <small className="form-text text-danger">{rent_error}</small>}
          </div>

          <div className="form-group">
            <label htmlFor="monthly_utilities_estimate">Monthly Utilities Estimate</label>
            <input type="number" className="form-control" name="monthly_utilities_estimate" value={monthly_utilities_estimate} onChange={this.handleInputChange} />
            {utilities_error && <small className="form-text text-danger">{utilities_error}</small>}
          </div>

          <div className="form-group">
            <label htmlFor="room_type">Room Type</label>
              <select className="form-control" id="room_type" value={room_type} onChange={this.handleDropdownChange}>
                <option value="">Select Room</option>
                <option value="Private">Private</option>
                <option value="Shared">Shared</option>
              </select>
            {room_type_error && <small className="form-text text-danger">{room_type_error}</small>}
          </div>

          <div className="form-group">
            <label htmlFor="start_date">Start Date &nbsp;</label>
            <DatePicker selected={start_date} onChange={this.handleStartDateChange} className="form-control" dateFormat="MM-dd-yyyy" maxDate={end_date} /> &nbsp;
            {start_date_error && <small className="form-text text-danger">{start_date_error}</small>}
          </div>

          <div className="form-group">
            <label htmlFor="end_date">End Date &nbsp;</label>
            <DatePicker selected={end_date} onChange={this.handleEndDateChange} className="form-control" dateFormat="MM-dd-yyyy" minDate={start_date} /> &nbsp;
            {end_date_error && <small className="form-text text-danger">{end_date_error}</small>}
          </div>

          <div className="form-group">
            <label htmlFor="male_flatmates_count">Number of Male Flatmates</label>
            <input type="number" className="form-control" name="male_flatmates_count" value={male_flatmates_count} onChange={this.handleInputChange} />
            {male_flatmates_error && <small className="form-text text-danger">{male_flatmates_error}</small>}
          </div>

          <div className="form-group">
            <label htmlFor="female_flatmates_count">Number of Female Flatmates</label>
            <input type="number" className="form-control" name="female_flatmates_count" value={female_flatmates_count} onChange={this.handleInputChange} />
            {female_flatmates_error && <small className="form-text text-danger">{female_flatmates_error}</small>}
          </div>

          <div className="form-group">
            <label htmlFor="highlights">Highlights</label>
            <textarea className="form-control" name="highlights" value={highlights} onChange={this.handleInputChange}></textarea>
          </div>

          {save_error && <small className="form-text text-danger">{save_error}</small>}
          <Button color="primary" onClick={this.submitButtonClicked}>Submit Listing</Button>
        </div>
      </div>
    );
  }
}

export default PostListing;
