import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from "./components/Layout/Layout";
import Home from "./components/Home/Home";
import PostListing from "./components/PostListing/PostListing";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div style={{ minHeight: '100vh', position: 'relative', paddingBottom: '80px', boxSizing: 'border-box'}}>
      <Router>
        <Layout>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/postListing" element={<PostListing />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
